import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Product from "./Product";
import Features from "./Features";
import PaymentPlan from "./PaymentPlan";
import AboutUs from "./AboutUs";
import ToCallAction from "./ToCallAction";
import Footer from "./Footer";

const OnePageSite = () => {
  return (
    <Box
      width="100%"  // Full width for the container
      bg="#F1F1F1"  // Background color for the entire page
    >
      {/* Navbar */}

      <Navbar />


      {/* Sections with ids for smooth scrolling */}
      <Box id="Home" width="100%" PaddingTop="185px">
        <Hero />
      </Box>
      <Box id="product" width="100%" marginTop="285px">
        <Product />
      </Box>
      <Box id="features" width="100%" marginTop="285px">
        <Features />
      </Box>
      <Box id="paymentPlan" width="100%" marginTop="285px">
        <PaymentPlan />
      </Box>
      <Box id="AboutUs" width="100%" marginTop="285px">
        <AboutUs />
      </Box>
      <Box width="100%" marginTop="285px">
        <ToCallAction />
      </Box>
      <Box width="100%" marginTop="285px">
        <Footer />
      </Box>
    </Box>
  );
};

export default OnePageSite;
