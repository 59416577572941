import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Flex,
  Button,
  Heading,
  Link,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import HamburgerIcon from "./HamburgerIcon.svg";
import { Link as ScrollLink } from "react-scroll";
import Logo from "./Logo.svg";
import LoginArrow from "./CallToActionArrowButton.svg";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  // Detect when navbar should become sticky and change background
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle navigation to /login
  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <Box width="100%" position="fixed" top="0" left="0" zIndex="999">
      {/* Wrap entire navbar */}
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        maxWidth="100%"
        paddingX={{ base: "20px", md: "45px" }} // Responsive padding for smaller screens
        paddingY="18px"
        bg={isSticky ? "#070707" : "transparent"}
        transition="background-color 0.3s ease"
      >
        {/* Logo */}
        <Image src={Logo} alt="logo-image" />

        {/* Desktop Menu */}
        {!isMobile && (
          <Flex gap="45px" justifyContent="center" flexWrap="wrap">
            {["Home", "Product", "Features", "AboutUs"].map((item) => {
              const section = item.toLowerCase().replace(/\s+/g, "");
              return (
                <Box
                  key={item}
                  padding="6px 18px"
                  alignItems="center"
                  borderRadius="8px"
                  bg="#161616"
                >
                  <ScrollLink
                    to={section}
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-70}
                  >
                    <Link
                      href="#"
                      color="#F1F1F1"
                      fontSize="12px"
                      fontWeight="400"
                      fontStyle="normal"
                    >
                      {item}
                    </Link>
                  </ScrollLink>
                </Box>
              );
            })}
          </Flex>
        )}

        {/* Hide the Login Button on mobile */}
        {!isMobile && (
          <Button
            variant="unstyled"
            borderRadius="14px"
            border="1px solid"
            borderColor="#4BFF5C"
            backgroundColor="#4BFF5C"
            color="#021A04"
            _hover={{
              color: "#F1F1F1",
              borderColor: "#F1F1F1",
              backgroundColor: "transparent", // No background on hover
            }}
            onClick={handleLoginClick} // Handle login button click
          >
            <Flex padding="8.37px 28.459px" alignItems="center" gap="3.348px">
              <Heading fontSize="12px" fontWeight="400">
                Login
              </Heading>
              <Image src={LoginArrow} alt="login-arrow" />
            </Flex>
          </Button>
        )}

        {/* Mobile Hamburger Icon */}
        {isMobile && (
          <IconButton
            icon={<Image src={HamburgerIcon} alt="hamburger-icon" boxSize="25px" />}
            aria-label="Open Menu"
            onClick={onOpen}
            variant="unstyled"
            color="white"
          />
        )}
      </Flex>

      {/* Mobile Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg="#070707" color="#F1F1F1">
            <DrawerCloseButton />
            <DrawerBody>
              <Flex flexDirection="column" alignItems="center" gap="20px" mt="60px">
                {["Home", "Product", "Feature", "About Us"].map((item) => (
                  <ScrollLink
                    key={item}
                    to={item.toLowerCase().replace(/\s+/g, "")}
                    smooth={true}
                    duration={500}
                    spy={true}
                    offset={-70}
                    onClick={onClose}
                  >
                    <Link fontSize="16px" fontWeight="400" _hover={{ color: "#4BFF5C" }}>
                      {item}
                    </Link>
                  </ScrollLink>
                ))}

                {/* Login Button for Mobile */}
                <Button
                  variant="unstyled"
                  borderRadius="14px"
                  border="1px solid"
                  borderColor="#4BFF5C"
                  backgroundColor="#4BFF5C"
                  _hover={{
                    color: "#F1F1F1",
                    borderColor: "#F1F1F1",
                    backgroundColor: "transparent", // No background on hover
                  }}
                  onClick={handleLoginClick} // Handle login button click for mobile
                >
                  <Flex padding="8.37px 28.459px" alignItems="center" gap="3.348px">
                    <Heading color="#021A04" fontSize="12px" fontWeight="400">
                      Login
                    </Heading>
                    <Image src={LoginArrow} alt="login-arrow" />
                  </Flex>
                </Button>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Navbar;
